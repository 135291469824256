import { AutenticacaoModel } from '@/@types/Models'
import { api } from '@/api/api'
import { queryClient } from '@/configurações/configuraçõesApi'
import { rotasApi } from '@/configurações/rotasApi'
import { rotasApp } from '@/configurações/rotasApp'
import { useUsuárioAtivoAtom } from '@/hooks/jotai/useUsuárioAtivoAtom'
import Yup from '@/utilitários/Yup/Yup'
import { abstractSchema } from '@/utilitários/Yup/abstractSchema'
import { responseError, responseSuccess } from '@/utilitários/handler/responseHandler'
import { LocalStorage } from '@/utilitários/localStorage/LocalStorage'
import { toastUi } from '@/utilitários/toast'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const useLogin = () => {
  const { setUsuárioAtivo } = useUsuárioAtivoAtom()
  const storage = new LocalStorage()
  const [searchBarParams] = useSearchParams()
  const navigate = useNavigate()

  const { mutate: login, isLoading: isLoadingLogin } = useMutation(signIn, {
    onSuccess: async (data) => {
      if (data.acesso === 'administrador' || data.acesso === 'escola'){
        return toastUi({
          title: 'Acesso negado',
          description: 'Uma conta é necessária para acessar o sistema',
          duration: 25000,
          status: 'error',
        })
      }
      await queryClient.invalidateQueries(['me'])
      setUsuárioAtivo(data)
      if (data?.token) storage.set('token', data.token)
      responseSuccess('Login realizado')
      navigate( rotasApp.início)
    },
    onError: (error: AxiosError) => responseError(error),
  })

  const loginContext = useForm({
    resolver: yupResolver(loginSchema),
    reValidateMode: 'onChange',
  })

  return { login, isLoadingLogin, loginContext }
}

async function signIn(data: LoginSchema): Promise<AutenticacaoModel> {
  const result = await api().post(rotasApi.signIn, data)
  return result.data.data
}

const loginSchema = Yup.object({
  email: abstractSchema.email.required(),
  senha: Yup.string().required(),
  verSenha: Yup.boolean().default(false).optional(),
})

export type LoginSchema = Yup.InferType<typeof loginSchema>
