import { UsuárioAutenticadoContexto } from '@/contextos/UsuárioAutenticado'
import { SplashPágina } from '@/páginas/publicas/Splash'
import { responseError } from '@/utilitários/handler/responseHandler'
import { Navigate } from 'react-router-dom'
import { useContextSelector } from 'use-context-selector'

export const AutenticaçãoCallback = ({ PageElement, redirectPathSuccess }: IAuthGuardProps) => {
  const status = useContextSelector(UsuárioAutenticadoContexto, (value) => value.status)
  const ambiente = import.meta.env.VITE_NODE_ENV

  if (status === 'error') {
    if (ambiente === 'desenvolvimento') {
      responseError('DEBUG: pagina não permitida')
      return <PageElement />
    } else {
      return <Navigate to={`/login`} />
    }
  } else if (status === 'success') {
    return <PageElement />
  } else if (status === 'loading') {
    return <SplashPágina />
  }
  return <SplashPágina />
}

export interface IAuthGuardProps {
  PageElement: () => JSX.Element
  redirectPathSuccess: string
}
