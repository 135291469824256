import { AlunoModel } from '@/@types/Models'
import { apiUpload } from '@/api/api'
import { queryClient } from '@/configurações/configuraçõesApi'
import { rotasApi } from '@/configurações/rotasApi'
import { Regex } from '@/utilitários/Regex'
import Yup from '@/utilitários/Yup/Yup'
import { abstractSchema } from '@/utilitários/Yup/abstractSchema'
import { responseError } from '@/utilitários/handler/responseHandler'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'

export const useAlunoEditar = () => {
  const {
    mutate: alunoEditarBase,
    isLoading: alunoEditarIsLoading,
    status: alunoEditarStatus,
  } = useMutation(endPoint, {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(['me'])
      await queryClient.invalidateQueries([`aluno-id_${data.id}`])
    },
    onError: (erro: AxiosError) => responseError(erro),
  })

  const alunoEditarContext = useForm({
    resolver: yupResolver(alunoEditarSchema),
    reValidateMode: 'onChange',
  })

  const alunoEditar = (data: AlunoEditarSchema) => {
    delete data.avatarUrl
    alunoEditarBase(data)
  }

  return {
    alunoEditar,
    alunoEditarIsLoading,
    alunoEditarContext,
    alunoEditarStatus,
  }
}

async function endPoint(data: AlunoEditarSchema): Promise<AlunoModel> {
  const result = await apiUpload().put(rotasApi.alunoAtualizar, data)
  return result.data.data
}

const alunoEditarSchema = Yup.object({
  id: Yup.string().required(),
  matricula: Yup.string().optional(),
  email: abstractSchema.email.required().label('E-mail'),
  celular: Yup.string().matches(Regex.CELULAR_MASCARA, 'Celular inválido').required().label('Celular'),
  deletarFoto: Yup.boolean().optional(),
  updateFoto: Yup.boolean().optional(),
  avatar: Yup.mixed().optional(),
  avatarUrl: Yup.string().optional().nullable().label('Avatar'),
  idade: Yup.number().required().label('Idade').min(8, 'Idade inválida'),
  nomeResponsavel: Yup.string()
    .optional()
    .when('idade', {
      is: (idade: number) => idade < 18,
      then: (schema) => schema.required('campo obrigatorio'),
    })
    .label('Nome do responsável'),
  cpfResponsavel: Yup.string()
    .optional()
    .when('idade', {
      is: (idade: number) => idade < 18,
      then: (schema) =>
        schema
          .matches(Regex.CPF_MASCARA, {
            message: 'Formato invalido',
            excludeEmptyString: true,
          })
          .required('campo obrigatorio'),
    })
    .label('CPF do responsável'),

  telefoneResponsavel: Yup.string()
    .optional()
    .when('idade', {
      is: (idade: number) => idade < 18,
      then: (schema) =>
        schema
          .matches(Regex.CELULAR_MASCARA, {
            message: 'Formato invalido',
            excludeEmptyString: true,
          })
          .required('campo obrigatorio'),
    })
    .label('Celular do responsável'),

  emailResponsavel: Yup.string()
    .optional()
    .when('idade', {
      is: (idade: number) => idade < 18,
      then: (schema) =>
        schema
          .matches(Regex.EMAIL, {
            message: 'Formato invalido',
            excludeEmptyString: true,
          })
          .required('campo obrigatorio'),
    })
    .label('E-mail do responsável'),
})

type AlunoEditarSchema = Yup.InferType<typeof alunoEditarSchema>
