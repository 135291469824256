import { BotãoFormulárioUi } from '@/componentes/Botões/BotãoFormulárioUi'
import { FormulárioUi } from '@/componentes/CompositionsPatterns/Formulário/FormulárioUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { TextoMdUi } from '@/componentes/Textos/TextoMdUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXxlUi } from '@/componentes/Textos/TextoXxlUi'
import { corPrimariaAtivoUi, corTextoUi } from '@/configurações/estilização/cores'
import { gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { useResponsávelVerificarCPF } from '@/hooks/API/responsável/useResponsávelVerificarCPF'
import { useResponsávelVerificarUUID } from '@/hooks/API/responsável/useResponsávelVerificarUUID'
import { useModalUi } from '@/hooks/useModalUi'
import { isErrosFormulário } from '@/utilitários/isErrosFormulário'
import { Flex, Img, Spinner } from '@chakra-ui/react'
import { WarningCircle } from '@phosphor-icons/react'
import { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { autorizarAlunoRotasApp } from './autorizarAluno.routes'

export const AutorizarAlunoVerificarResponsável = () => {
  const { id } = useParams()
  const { data: alunoData, isLoading, isError } = useResponsávelVerificarUUID({ id })
  const { verificarCPFmutate, verificarCPFisLoading, verificarCPFstatus, verificarCPFcontext } = useResponsávelVerificarCPF()

  const { UiModal, onOpen: openModal } = useModalUi()
  const navigate = useNavigate()

  const { formState, setValue, clearErrors } = verificarCPFcontext
  const { errors } = formState

  useEffect(() => {
    setValue('alunoUuidAutorizacao', id || "")
    clearErrors()
  }, [])

  useEffect(() => {
    if (verificarCPFstatus === 'success') openModal()
  }, [verificarCPFstatus])

  if (!alunoData) {
    return (
      <FlexColumnUi maxWidth={500} gap={gapUi['10xl']} padding={paddingUi.md} alignItems="center">
        <Img src="/logo.png" alt="Logo da empresa" width="250px" />
        {isLoading && <Spinner size="xl" color={corPrimariaAtivoUi} />}
        {isError && (
          <FlexColumnUi gap={gapUi.xl}>
            <Flex gap={gapUi.md} alignItems="center">
              <WarningCircle size={24} color={corPrimariaAtivoUi} />
              <TextoXxlUi color={corPrimariaAtivoUi} fontWeight="500">
                Página não encontrada
              </TextoXxlUi>
            </Flex>
            <TextoMdUi color={corTextoUi.primaria} lineHeight="125%">
              Link inválido. Por favor verifique o e-mail para acessar o link recebido. Caso o processo de autorização ja tenha
              sido realizado, favor desconsiderar.
            </TextoMdUi>
          </FlexColumnUi>
        )}
      </FlexColumnUi>
    )
  }

  return (
    <>
      <FlexColumnUi maxWidth={500} gap={gapUi['6xl']} padding={paddingUi.md}>
        <Img src="/logo.png" alt="Logo da empresa" width="250px" alignSelf="center" />
        <FlexColumnUi gap={gapUi.md}>
          <TextoXxlUi color={corPrimariaAtivoUi} fontWeight="500">
            Validar dados
          </TextoXxlUi>
          <TextoSmUi color={corTextoUi.primaria} lineHeight="125%">
            Confirme seu CPF para prosseguir para a tela de autorização do aluno {alunoData.nome}
            {alunoData.CPF && `, com CPF ${alunoData.CPF}`}
          </TextoSmUi>
        </FlexColumnUi>

        <FormProvider {...verificarCPFcontext}>
          <FormulárioUi.Form callback={verificarCPFmutate}>
            <Flex width="100%" flexDirection="column" gap={gapUi['4xl']}>
              <FormulárioUi.Container name="responsavelCPF" disabled={verificarCPFisLoading}>
                <FormulárioUi.Label título="CPF do responsável" />
                <FormulárioUi.InputMáscara tipo="cpf" />
                <FormulárioUi.MensagemErro />
              </FormulárioUi.Container>

              <Flex width="100%" flexDirection="column" gap={gapUi.xl}>
                <BotãoFormulárioUi
                  isLoading={false}
                  type="submit"
                  isDisabled={isErrosFormulário(errors) || verificarCPFisLoading}
                >
                  {verificarCPFisLoading
                    ? 'Verificando CPF...'
                    : (isErrosFormulário(errors) && 'Preencha os campos corretamente') || 'Verificar CPF'}
                </BotãoFormulárioUi>
              </Flex>
            </Flex>
          </FormulárioUi.Form>
        </FormProvider>
      </FlexColumnUi>

      <UiModal
        callbackBotão={() => navigate(autorizarAlunoRotasApp.alunoConfirmar)}
        descrição="Você será redirecionado para a página de autorização de acesso"
        textoBotão="Entendido"
        título="CPF verificado"
      />
    </>
  )
}
